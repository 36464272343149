<template>
  <div v-if="open"
       class="module-widget-modal text-white">
    <div class="backdrop bg-primary-900 bg-opacity-90 fixed left-0 right-0 top-0 bottom-0" @click="handleClose"></div>
    <div class="w-page page-padding modal-main-dialog">
      <button class="close-action" @click="handleClose">
        <svg xmlns="http://www.w3.org/2000/svg" width="15.1" height="15.1" viewBox="0 0 15.1 15.1">
          <path id="Union_2" data-name="Union 2"
                d="M3981.318-2751.733l-5.2-5.2-5.2,5.2a1.378,1.378,0,0,1-1.949,0,1.377,1.377,0,0,1,0-1.949l5.2-5.2-5.2-5.2a1.376,1.376,0,0,1,0-1.948,1.376,1.376,0,0,1,1.949,0l5.2,5.2,5.2-5.2a1.379,1.379,0,0,1,1.949,0,1.378,1.378,0,0,1,0,1.948l-5.2,5.2,5.2,5.2a1.378,1.378,0,0,1,0,1.949,1.376,1.376,0,0,1-.975.4A1.373,1.373,0,0,1,3981.318-2751.733Z"
                transform="translate(-3968.571 2766.429)" fill="#fff"/>
        </svg>
      </button>
      <div class="shadow-2xl bg-primary-800 rounded-2xl flex flex-nowrap sm-no-round" v-if="!loading && module">
        <module-details :module="module" :use-inner-padding="true"></module-details>
      </div>
      <div v-if="loading" class="shadow-2xl bg-primary-800 rounded-2xl p-8 flex">
        <svg class="m-auto" xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" stroke="#fff">
          <g fill="none" fill-rule="evenodd">
            <g transform="translate(1 1)" stroke-width="2">
              <circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
              <path d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s"
                                  repeatCount="indefinite"/>
              </path>
            </g>
          </g>
        </svg>
      </div>
      <div v-else-if="!module" class="shadow-2xl bg-primary-800 rounded-2xl p-8 flex">
        <svg class="m-auto" xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" stroke="#fff">
          <g fill="none" fill-rule="evenodd">
            <g transform="translate(1 1)" stroke-width="2">
              <circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
              <path d="M36 18c0-9.94-8.06-18-18-18">
                <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s"
                                  repeatCount="indefinite"/>
              </path>
            </g>
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import Component from 'vue-class-component';
import Vue from 'vue';
import { mapState } from 'vuex';
import ModuleDetails from '@/components/module-details/module-details.vue';

@Component({
  name: 'module-detail-modal',
  components: {
    ModuleDetails,
  },
  data() {
    return {
      previousRoute: null,
    };
  },
  beforeDestroy() {
    this.$store.commit('moduleModal/SET_OPEN', false);
  },
  methods: {
    async openModuleLink() {
      this.$router.replace({ hash: `/module/${this.module.slug}` });
    },
    handleClose() {
      this.$store.commit('moduleModal/SET_OPEN', false);
      this.$router.replace({ hash: undefined });
    },
  },
  watch: {
    loading(b) {
      if (!b && this.module && this.open) {
        this.openModuleLink();
      }
    },
  },
  computed: {
    ...mapState({
      open: (s) => s.moduleModal.open,
      loading: (s) => s.moduleModal.loading,
      module: (s) => s.moduleModal.module,
    }),
  },
})
export default class ModuleDetailModal extends Vue {
}
</script>

<style scoped>

</style>
